<template>
  <Breadcrumb :home="breadcrumb.home" :model="breadcrumb.items" style="padding-top:0px;background: none;border: none;" />
  <div class="card">
    <Toolbar class="p-mb-4" style="background-color: #fff;border:none;padding-bottom:0px;">
      <template #left>
        <!--        <Button style="background: #12A25D" label="创建项目" class="p-mr-2" @click="create()"/>-->
        <Button v-if="buttons.gdBt" label="归档" class="p-mr-2 p-button-outlined p-button-secondary" @click="archive()" />
        <Button v-if="buttons.scBt" label="删除" class="p-mr-2 p-button-outlined p-button-secondary" @click="removeSelected()" />
      </template>

      <template #right>
        <div class="p-inputgroup">
          <InputText @keypress="$event.keyCode==13?query():{}" class="p-mr-2" style="width:200px;" v-model="dataTable.queryParams.name" placeholder="请输入项目名称" />
          <InputText @keypress="$event.keyCode==13?query():{}" class="p-mr-2" style="width:200px;" v-model="dataTable.queryParams.userName" placeholder="请输入小组人员名称" />

          <Button @click="query()" icon="pi pi-search" style="background: #FFFFFF;color: #000000;opacity: 0.25;border-radius: 5px" />
        </div>
      </template>
    </Toolbar>

    <DataTable :scrollable="true" style="width:100%;" :rowHover="true" class="p-datatable-customers" :value="dataTable.gridData" v-model:selection="dataTable.selected" :lazy="true" :loading="dataTable.loading"
      @page="onPage($event)" :totalRecords="dataTable.totalRecords" responsiveLayout="scroll" :paginator="true" :rows="10" paginatorTemplate="PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport"
      :rowsPerPageOptions="[10,20,50,100]" currentPageReportTemplate="共 {totalRecords} 条">
      <template #empty>
        没有发现数据。
      </template>
      <Column headerStyle="width: 3rem;" selectionMode="multiple"></Column>
      <Column field="code" header="项目编号" style="min-width:12rem"></Column>
      <Column field="name" header="项目名称" style="min-width:20rem"></Column>
      <Column field="userlist" header="项目成员" style="min-width:18rem">
        <template #body="slotProps">
          <label v-bind:key="item" v-for="(item, index) of slotProps.data.showusers">
            <span v-if="item.is_leader == 1">
              <i v-tooltip="'组长'" class="pi pi-user" style="color:rgba(82, 196, 26, 1);border-radius: 50px;border:1px solid rgba(183, 235, 143, 1);font-size:8px;background-color: rgba(246, 255, 237, 1);padding:2px;"></i>
              {{ item.name }}
            </span>
            <span v-else>
              {{ item.name }}
            </span>
            <span v-if="index &lt; slotProps.data.showusers.length-1">，</span>
          </label>
          <span style="cursor:pointer;" v-if="slotProps.data.hideusers" v-tooltip="slotProps.data.hideusers"> ...</span>
        </template>
      </Column>
      <Column field="fulllocation" header="项目地点" style="min-width:20rem"></Column>
      <Column field="position" header="坐标" style="min-width:23rem"></Column>
      <Column field="type" header="项目类型" style="min-width:12rem"></Column>
      <Column field="aim" header="目标" style="min-width:20rem"></Column>
      <Column :exportable="false" header="操作" frozen alignFrozen="right" style="min-width:22rem;">
        <template #body="slotProps">
          <a @click="$router.push({path:'/showSuggest',query:{id:slotProps.data.planId}})" class="a-button"
            style="padding-right: 8px;padding-left:8px;border-right:1px solid rgba(216, 216, 216, 1);margin: 0;cursor: pointer">专家建议</a>
          <a v-if="buttons.ssyhBt" href="javascript:" class="a-button" style="padding-right: 8px;padding-left:8px;border-right:1px solid rgba(216, 216, 216, 1);margin: 0" @click="setUser(slotProps.data)">所属用户</a>
          <a v-if="buttons.bjBt" href="javascript:" style="padding-right: 8px;padding-left:8px;border-right:1px solid rgba(216, 216, 216, 1);margin: 0" class="a-button" @click="edit(slotProps.data)">编辑</a>
          <a href="javascript:" class="a-button" @click="$router.push('/project/show?id='+slotProps.data.id)">查看</a>
        </template>
      </Column>
      <template #paginatorLeft></template>
      <template #paginatorRight>
        <div class="p-field">
          <label>跳至&nbsp;&nbsp;</label>
          <InputNumber @keypress="jumpPage()" v-tooltip.focus="'按下回车键后跳转'" inputStyle="width: 40px;" id="integeronly" v-model="dataTable.jumpPageNum" />
          <label>&nbsp;&nbsp;页</label>
        </div>
      </template>
    </DataTable>

    <UserSetting ref="usersetting"></UserSetting>
    <ArchiveForm ref="archiveForm"></ArchiveForm>
    <Toast />

  </div>
</template>

<script>
import UserSetting from "./UserSetting";
import ArchiveForm from "@/views/modules/project/processing/ArchiveForm";

export default {
  components: { UserSetting, ArchiveForm },
  data() {
    return {
      form: {
        id: null,
        name: null,
        telephone: null,
        role: null,
        email: null,
        team: null,
        $submitted: false,
        $formMode: "create",
      },
      breadcrumb: {
        home: { label: "种植项目", to: "#" },
        items: [
          { label: "项目管理", to: "#" },
          { label: "进行中的项目", to: "#" },
        ],
      },
      dataTable: {
        gridData: [],
        selected: null,
        queryParams: {
          name: null,
          userName: null,
        },
        lazyParams: {
          page: 0,
        },
        loading: true,
        totalRecords: 0,
        jumpPageNum: 1,
        lastParams: null,
      },
      buttons:{
        gdBt:false,
        scBt:false,
        ssyhBt:false,
        bjBt:false
      }
    };
  },
  mounted() {
    if (localStorage.getItem("menuList")) {
      JSON.parse(localStorage.getItem("menuList")).forEach((item, index) => {
        if (
          item.name == "种植项目" &&
          JSON.parse(localStorage.getItem("menuList"))[index].children
        ) {
          JSON.parse(localStorage.getItem("menuList"))[index].children.forEach(
            (v, i) => {
              if (
                v.name == "项目管理" &&
                JSON.parse(localStorage.getItem("menuList"))[index].children[i]
                  .children
              ) {
                JSON.parse(localStorage.getItem("menuList"))[index].children[
                  i
                ].children.forEach((a, b) => {
                  if (
                    a.name == "进行中的项目" &&
                    JSON.parse(localStorage.getItem("menuList"))[index]
                      .children[i].children[b].buttons
                  ) {
                    JSON.parse(localStorage.getItem("menuList"))[
                      index
                    ].children[i].children[b].buttons.forEach((c) => {
                      switch (c.name) {
                        case "归档":
                          this.buttons.gdBt = true;
                          break;
                        case "删除":
                          this.buttons.scBt = true;
                          break;
                        case "所属用户":
                          this.buttons.ssyhBt = true;
                          break;
                        case "编辑":
                          this.buttons.bjBt = true;
                          break;
                        default:
                          return;
                      }
                    });
                  }
                });
              }
            }
          );
        }
      });
    }
    this.loadTable();
  },
  methods: {
    jumpPage() {
      if (this.dataTable.jumpPageNum > 0 && event.keyCode == 13) {
        this.dataTable.lazyParams.page = this.dataTable.jumpPageNum - 1;
        this.loadTable(this.dataTable.lastParams);
      }
    },
    query() {
      if (this.dataTable.queryParams) {
        this.loadTable({
          name: this.dataTable.queryParams.name,
          userName: this.dataTable.queryParams.userName,
        });
      } else {
        this.loadTable();
      }
    },

    /**
     * 加载表格数据
     */
    loadTable(params) {
      let me = this;
      me.loading = true;
      this.dataTable.lastParams = params;
      let targetParmas = {
        current: this.dataTable.lazyParams.page + 1,
        size: this.dataTable.lazyParams.rows,
      };
      if (params) {
        Object.assign(targetParmas, params);
      }
      this.$http("/project/list", "get", targetParmas, function (response) {
        me.dataTable.gridData = response.data.records;
        // 将组长排在第一位
        for (let i in me.dataTable.gridData) {
          let row = me.dataTable.gridData[i];
          let userlist = JSON.parse(row.userlist);
          if (userlist) {
            userlist.sort((a) => {
              if (a.is_leader == 1) {
                return -1;
              } else {
                return 0;
              }
            });
            row.userlist = userlist;
            // 只显示前3个用户
            row.showusers = JSON.parse(JSON.stringify(userlist)).slice(0, 3);
            // 将之后的几个用户单独存起来
            if (userlist.length > 3) {
              let hideusers = JSON.parse(JSON.stringify(userlist)).slice(3);
              let str = "";
              for (let i in hideusers) {
                str += hideusers[i].name + "，";
              }
              str = str.substring(0, str.length - 1);
              row.hideusers = str;
            }
          }
        }
        me.dataTable.totalRecords = response.data.total;
        me.dataTable.loading = false;
        me.dataTable.selected = [];
      });
    },

    onPage(event) {
      this.dataTable.lazyParams = event;
      this.loadTable(this.dataTable.lastParams);
    },

    create() {
      this.$router.push("/project/form");
    },

    view(row) {
      this.$router.push({
        path: "/project/form",
        query: { action: "view", id: row.id },
      });
    },

    edit(row) {
      this.$router.push({
        path: "/project/form",
        query: { action: "edit", id: row.id },
      });
    },

    archive() {
      if (!this.dataTable.selected || this.dataTable.selected.length == 0) {
        this.$toast.add({
          severity: "warn",
          summary: "错误",
          detail: "请选择待归档的项目！",
          life: 3000,
        });
        return;
      }

      this.$refs.archiveForm.show(this.dataTable.selected, this);
    },

    setUser(row) {
      this.$refs.usersetting.show(this, [row]);
    },

    removeSelected() {
      let me = this;
      if (!this.dataTable.selected || this.dataTable.selected.length == 0) {
        this.$toast.add({
          severity: "warn",
          summary: "错误",
          detail: "请选择待删除的项目！",
          life: 3000,
        });
        return;
      }

      this.$confirm.require({
        header: "确认",
        message: "确认要删除所选的项目吗？",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "确认",
        rejectLabel: "取消",
        accept: () => {
          let ids = "";
          for (let i in me.dataTable.selected) {
            ids += me.dataTable.selected[i].id + ",";
          }
          ids = ids.substring(0, ids.length - 1);
          this.$http("/project/remove", "get", { ids: ids }, () => {
            me.$toast.add({
              severity: "success",
              summary: "成功",
              detail: "所选项目删除成功！",
              life: 3000,
            });
            me.loadTable(me.dataTable.lastParams);
          });
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.a-button {
  color: #12a25d;
  font-size: 14px;
  margin-left: 8px;
  margin-right: 8px;
}
.p-mr-2 {
  width: 88px;
  border-color: #d9d9d9 !important;
}

.admin-status {
  width: 56px;
  height: 22px;
  border: 1px double #91d5ff;
  color: #1890ff;
  font-size: 12px;
  padding: 4px 8px 4px 8px;
  background-color: #e6f7ff;
}

.user-status {
  width: 56px;
  height: 22px;
  border: 1px double #b7eb8f;
  color: #52c41a;
  font-size: 12px;
  padding: 4px 8px 4px 8px;
  background-color: #f6ffed;
}

//去掉表格底部的线条
::v-deep(.p-paginator-bottom) {
  border-bottom: none !important;
}

::v-deep(.p-checkbox .p-checkbox-box.p-highlight) {
  border-color: #12a25d;
  background: #12a25d;
}

::v-deep(.p-checkbox .p-checkbox-box.p-highlight:hover) {
  border-color: #12a25d;
  background: #12a25d;
}
::v-deep(.p-checkbox .p-checkbox-box:hover) {
  border-color: #12a25d;
}
.p-column-title {
  padding-left: 22px;
}
</style>
